<template>
  <v-container fluid class="ma-0 pa-0" :class="{'light-background': $vuetify.breakpoint.xsOnly, 'white': $vuetify.breakpoint.smAndUp}">
    <v-layout row wrap :pa-5="$vuetify.breakpoint.smAndUp">
      <v-flex xs12 class="max-width-1400 full-width ma-auto px-2">
        <v-layout row wrap>
          <v-flex xs12 pa-3 text-xs-center>
            <p class="graphik-medium font-25 mb-4 dark-grey--text">{{ $ml.get('login_recover_password') }}</p>
          </v-flex>
        </v-layout>

        <v-layout row wrap>
          <v-flex xs12 offset-sm2 sm8 offset-md3 md6 pa-3 text-xs-center>
            <v-card color="light-background" flat tile>
              <v-card-text class="px-4">
                <v-form v-model="valid_reset">
                  <v-layout row wrap>
                    <v-flex xs12>
                      <p class="graphik-medium text-xs-left mt-3 font-17 mb-2 dark-grey--text">{{ $ml.get('fields_token') }}</p>

                      <v-text-field
                        v-model="reset.token"
                        solo
                        clearable
                        required
                        disabled
                        class="hidden-shadow small-input"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>

                  <v-layout row wrap>
                    <v-flex xs12>
                      <p class="graphik-medium text-xs-left font-17 mb-2 dark-grey--text">{{ $ml.get('fields_password') }}</p>

                      <v-text-field
                        v-model="reset.password"
                        solo
                        clearable
                        required
                        :disabled="g_isLoading"
                        :rules="g_password_rules"
                        class="hidden-shadow small-input"
                        :type="hidePassword ? 'password' : 'text'"
                        :append-icon="hidePassword ? 'visibility' : 'visibility_off'"
                        @click:append="() => (hidePassword = !hidePassword)"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12>
                      <v-btn 
                        color="primary"
                        depressed dark
                        :loading="g_isLoading"
                        class="normalcase graphik-bold-italic ma-0 border-radius-5"
                        @click="resetPassword"
                      >{{ $ml.get('login_recover_password') }}</v-btn>
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout> 
      </v-flex>
    </v-layout>

    <message-dialog :dialog="g_dialog"></message-dialog>
    <loading-dialog :isLoading="g_isLoading"></loading-dialog>
  </v-container>
</template>

<script>
export default {
  name: 'resetPassword',
  metaInfo: {
    title: process.env.VUE_APP_METATAGS_TITLE_RESET_PASSWORD,
    meta: [
      {
        vmid: 'og:title',
        property: 'og:title',
        content: process.env.VUE_APP_METATAGS_TITLE_RESET_PASSWORD
      },
      {
        vmid: 'twitter:title',
        property: 'twitter:title',
        content: process.env.VUE_APP_METATAGS_TITLE_RESET_PASSWORD
      }
    ]
  },
  data () {
    return {
      valid_reset: false,
      hidePassword: true,
      reset: {
        token: this.$route.params.token,
        password: ''
      }
    }
  },
  methods: {
    resetPassword () {
      this.g_isLoading = true
      this.$api.resetPassword(
        this.reset,
        () => {
          this.g_dialog = {
            title: this.$ml.get('general_success'),
            message: this.$ml.get('login_reset_password_success'),
            show: true,
            return: true,
            path_to_return: '/iniciarsesion'
          }
          this.g_isLoading = false
        },
        response => this.$errorHandling(response).then(() => {
          this.resetPassword()
        }, () => {})
      )
    }
  }
}
</script>

<style scoped>

</style>
